import { React } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'primereact/button'


export default function NuevoSuscriptor() {

    const urlParams = useParams()

    return (
        <div align="center" className='contenedor'>
            <p><i className="pi pi-file-check color-success" style={{ fontSize: '2.5rem', color: 'green' }}></i></p>
            <p>¡Acabamos de registrar tus datos! <br />Para compeltar el registro, deberás vincular tu tarjeta de crédito.</p>
            <p><a href={atob(urlParams.url)} target='_blank'><Button label="Vincular Ahora" /></a></p>

            <br /><p><em>También podrás vincularla desde el correo electrónico que acabamos de enviarte.</em></p>
        </div>
    )

}