import { tiendasApi } from '../api'

export const mobbexSuscriptorApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        createSuscriptor: builder.mutation({
            query: ({body}) => ({
                url: 'mobbex/suscriptores',
                method: 'POST',
                body: body
            })
        }),
    })
})

export const {
    useCreateSuscriptorMutation,
} = mobbexSuscriptorApiSlice