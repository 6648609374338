import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: (window.location.href.includes("red.cafira.com") ? 'https://red-api.cafira.com/api/web/' : 'http://localhost:3800/api/web/' ),
  //credentials: 'include',
})


export const tiendasApi = createApi({
  reducerPath: 'tiendasApi',
  baseQuery: baseQuery,
  keepUnusedDataFor: 20,
  endpoints: builder => ({}),
})
