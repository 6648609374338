import { React } from 'react'




export default function Home() {


    return (
        <>
            <div align="center">
                <div style={{ width: '100%' }}>
                    <img style={{ width: '100%' }} src='https://cafira.sfo2.digitaloceanspaces.com/red-cafira/home2/1.jpg' />
                    <img style={{ width: '100%' }} src='https://cafira.sfo2.digitaloceanspaces.com/red-cafira/home2/2.jpg' />
                    <img style={{ width: '100%' }} src='https://cafira.sfo2.digitaloceanspaces.com/red-cafira/home2/3.jpg' />
                    <img style={{ width: '100%' }} src='https://cafira.sfo2.digitaloceanspaces.com/red-cafira/home2/4.jpg' />
                </div>
            </div>
        </>
    )

}