import { useState } from 'react'

import { Dropdown } from 'primereact/dropdown'
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"

import '../../assets/css/form.css'

export default function Select({ tituloMini, value, onChange, icon, placeholder, opciones, required }) {

    const [selectedValor, setSelectedValor] = useState(value)

    const handleChange = (e) => {

        setSelectedValor(e.target.value)
        onChange(e.target.value)

    }

    return (
        <>
            {tituloMini && <p className='tituloMini'>{tituloMini}</p>}
            <div className="card flex justify-content-center">
                <IconField iconPosition="left" style={{ width: '100%' }}>
                    <InputIcon className={icon} style={{ zIndex: 9 }}> </InputIcon>
                    <Dropdown
                        value={selectedValor}
                        onChange={(e) => handleChange(e)}
                        options={opciones}
                        placeholder={placeholder}
                        className="w-full text-left"
                        style={{ paddingLeft: '28px' }}
                        required={required}
                    />
                </IconField>
            </div>
        </>
    )

}