import { React } from 'react'

import { Divider } from 'primereact/divider'


export default function Contacto() {


    return (
        <>
            <div align="center">
                <p><span className="pi pi-envelope"></span> <a href="mailto:redcafira@cafira.com">redcafira@cafira.com</a></p>
                <Divider />
                <p><span className="pi pi-phone"></span> <a href="tel:+5491136956081">+54 9 11 3695 6081</a></p>
                <Divider />
                <p><span className="pi pi-whatsapp"></span> <a href="https://wa.me/5491136956081">+54 9 11 3695 6081</a></p>
                <Divider />
                <p><span className="pi pi-instagram"></span> <a href="https://www.instagram.com/expocafira">expocafira</a></p>
            </div>
        </>
    )

}