import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Menu from './components/elementos/Menu'

//Componentes Vistas
import Home from './components/vistas/Home'
import NuevoSuscriptor from './components/vistas/NuevoSuscriptor'
import SuscriptoOk from './components/vistas/SuscriptoOk'
import Contacto from './components/vistas/Contacto'


export default function Router() {



    return (
        <BrowserRouter>

            <Menu />
            
                <Routes >

                    <Route path="/" element={<Home />} />
                    <Route path="/nuevo-suscriptor" element={<NuevoSuscriptor />} />
                    <Route path="/suscripto-ok/:url" element={<SuscriptoOk />} />
                    <Route path="/contacto" element={<Contacto />} />

                </Routes>
       


        </BrowserRouter >
    );

}