
import React from 'react'
import { Menubar } from 'primereact/menubar'
import { PrimeIcons } from 'primereact/api'
import { useNavigate } from 'react-router-dom';

export default function TemplateDemo() {

    const navigate = useNavigate()

    const items = [
        {
            label: 'Home',
            icon: PrimeIcons.HOME,
            command: () => navigate('/')
        },
        {
            label: 'Suscribirme',
            icon: 'pi pi-user-plus',
            command: () => navigate('/nuevo-suscriptor')
        },
        {
            label: 'Contacto',
            icon: 'pi pi-phone',
            command: () => navigate('/contacto')
        }
    ];

    const start = <img alt="logo" src="https://cafira.sfo2.digitaloceanspaces.com/red-cafira/logo.jpg" height="40" className="mr-2"></img>;


    return (
        <div className="card">
            <Menubar model={items} start={start} />
        </div>
    )
}
